:root {
    --gradient-blue: linear-gradient(270deg, #3292CD 0%, #246B98 100%);

    --bg-main: #F5F6F6;
    --bg-secondary: #FFFFFF;

    --text-color-main: #000;
    --accent-color: #3292CD;
    --accent-color-secondary: rgba(36, 107, 152, 0.16);
    --accent-color-secondary-border: rgba(36, 107, 152, 0.5);
    --accent-blue-bg: #D5F5FD;
    --accent-green-bg: #D5F1CE;
    --accent-text-color-blue: #236373;
    --accent-text-color-green: #1C8901;

    --card-bg: rgba(255, 255, 255);
    --border-main: #E5E7EB;
}

body.theme-dark {
    --bg-main: #000;
    --bg-secondary: transparent;
    --accent-color-secondary: rgba(36, 107, 152, 0.5);

    --text-color-main: #FFF;

    --card-bg: rgba(7, 22, 29);
    --border-main: #213138;
}
