* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,
html {
    width: 100vw;
    overflow-x: hidden;
}

body {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 132%;
    color: var(--text-color-main);
}

.page-section {
    padding: 32px;
    max-width: 1400px;
    margin-inline: auto;

    @media (max-width: 500px) {
        padding-inline: 16px;
        padding:8px;
    }
}

.btn {
    border: 1px solid #E7E5E6;
    background: transparent;
    color: var(--text-color-main);
    border-radius: 4px;
    padding: 8px 16px;
    width: max-content;

    &:disabled {
        opacity: .3;
        pointer-events: none;
    }
}

.link {
    color: var(--accent-color);
    text-decoration: underline;
}

.card {
    padding: 16px;
    border-radius: 4px;
    border: 1px solid var(--border-main);
    position: relative;
    overflow: hidden;
    box-shadow: 0px 8px 56px 0px #0000003D;

    &::after {
        background: var(--card-bg);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        -moz-backdrop-filter: blur(4px);
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
}
